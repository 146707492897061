import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import StudyDesign from '../../../components/study-design'

import chart1 from '../../../images/mean_estradiol_suppression_2022.png'
import chart2 from '../../../images/Sustained_LH_Chart.png'

const metaTags = {
  description: 'See gonadal suppression clinical data and study design for SUPPRELIN® LA (histrelin acetate) subcutaneous implant. View Important Safety Information.',
  keywords: 'Homepage',
  title: 'Gonadal Suppression | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true}>
    <Row>
      <Col xs={12}>
        <h2 className="eye-brow">Secondary Efficacy Hormone Suppression Assessments:</h2>
        <h1>Gonadal suppression<sup>1,3</sup></h1>
      </Col>
    </Row>
    <Row center="xs">
      <Col xs={12}>
        <img src={chart1} alt="Gonadal Data 6 Years" title="Gonadal Data 6 Years" />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <StudyDesign>
          <p className='study-copy'><strong>STUDY DESIGN:</strong> The initial Phase 3, prospective, multicenter, open-label study was conducted in 36 children (33 females and 3 males), 4.5 to 11.6 years of age, to evaluate the efficacy and safety of a 50-mg histrelin subcutaneous implant for the treatment of CPP in treatment-naïve (n=20) and pretreated (n=16) children. Efficacy assessments included endpoints that measured the suppression of gonadotropins (LH and FSH) and gonadal sex steroids (estrogen in girls and testosterone in boys) on treatment. Other assessments were clinical (evidence of stabilization or regression of signs of puberty) or gonadal steroid-dependent (bone age advancement, linear growth). The initial duration of therapy was 12 months (plus a 1-month follow-up period). At the 12-month visit, all patients had their implant from Day 1 removed and patients who continued to meet all efficacy and safety parameters (as determined by the investigators) (n=31) were eligible to receive a new 50-mg histrelin subcutaneous implant and continue into the extension phase. Every 12 months for up to 72 months, a patient’s implant was removed and a new implant inserted, until the patient no longer required hormone suppression or the patient discontinued from the study. The primary endpoint was LH suppression as measured in response to GnRHa stimulation. Efficacy assessments also included secondary endpoints that measured the suppression of FSH and gonadal sex steroids (estrogen in girls and testosterone in boys). Other assessments were clinical (evidence of stabilization or regression of signs of puberty) or gonadal steroid-dependent (bone age advancement, linear growth).<sup>1,3</sup></p>
          <p className='study-copy'>FSH=follicle-stimulating hormone.
            <br />GnRHa=gonadotropin-releasing hormone agonist.
            <br />LH=luteinizing hormone.
            </p>
        </StudyDesign>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>

        <h2 className='eye-brow'> In a Clinical Trial:</h2>
        <h1>Gonadal suppression through Month 12<sup>1</sup></h1>
        <ul className='brand-list'>
          <li>100% estradiol suppression (n=33) through Month 9 and 97% at Month 12</li>
          <li>Testosterone suppression was maintained in the 3 pretreated males</li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <h2 className='eye-brow'> In the Clinical Trial Extension Phase:</h2>
        <h1>Sustained gonadal suppression through 6 years<sup>3</sup></h1>
        <ul className='brand-list'>
          <li>Suppression of mean estradiol concentrations below the predetermined threshold of 73.42 pmol/L was consistent throughout the 72-month study period</li>
          <li>Testosterone levels were maintained at or below 0.521 nmol/L during treatment, which is half of the predetermined suppression level of 1.041 nmol/L</li>
        </ul>
        <p><strong>Discontinuation of SUPPRELIN<sup>&reg;</sup> LA should be considered at the discretion of the physician and at the appropriate time point for the onset of puberty (approximately 11 years for females and 12 years for males).<sup>1</sup> </strong></p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/bone-age-to-chronological-age'}
          LinkCaption="See data: sustained decrease in bone-age-to-chronological-age ratio - gonadal hormone clinical data"
        >
           See data: sustained decrease in bone-age-to-chronological-age ratio
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/once-yearly-dosing"
          LinkCaption="Learn how SUPPRELIN LA delivers a full year of medication in a single implant - gonadal hormone clinical data"
        >
          Learn how SUPPRELIN<sup>&reg;</sup> LA delivers a full year of medication in a single implant
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
